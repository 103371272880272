/* eslint-disable max-classes-per-file */
import * as React from 'react';
import type { Entities } from '@inwink/entities/entities';
import { connectwith } from '@inwink/react-utils/decorators/connectwith';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { Loader } from '@inwink/loader';
import * as History from 'history';
import type { IRequestManager } from '../services/apiaccessprovider.definition';
// import { RequireConnexionPage } from '../routes/requireconnexion/requireconnexion';
import { DynamicPage } from "./dynamicpage/dynamicpage";
import { actions as pageActions, IEntityProviderCallback } from '../services/pageservice';
import { Loadable } from './loadable';
import type { IDefaultEventPageProps } from '../data/visualtheme';
import type { States } from '../services/services';
import { trackError, TrackingShardType } from '@@api/front/tracking.error';
import { isFromServer } from './dynamicpage/helpers';

// eslint-disable-next-line import/no-cycle
const RequireConnexionPage = React.lazy(() => import("../routes/requireconnexion/requireconnexion"));

export interface IPageProps {
    rootwebsite?: States.IRootWebsiteState;
    event?: States.IEventState;
    community?: States.ICommunityState;
    user?: States.IAppUserState;
    history?: History.History;
    className?: string;
    allowedOnly?: boolean;
    message?: string;
    registeredmessage?: string;
    location?: any;
    children?: React.ReactNode;
}

export function handlePageError(props, error, info) {
    console.error("page error", error, info);
    let loc = typeof window !== "undefined" && window.location.href;
    if (props.location) {
        loc = props.location.pathname + props.location.search;
    }
    let shardType: TrackingShardType;
    let shardId;
    let requestMgr: IRequestManager;
    if (props.event?.requestManagers?.apiFront) {
        requestMgr = props.event.requestManagers.apiFront;
        shardType = 'event';
        shardId = props.event.eventid;
    } else if (props.community?.requestManagers?.apiFront) {
        requestMgr = props.community.requestManagers.apiFront;
        shardType = 'community';
        shardId = props.community.communityid;
    } else if (props.rootwebsite?.requestManager?.apiFront) {
        requestMgr = props.rootwebsite.requestManager.apiFront;
        shardType = 'website';
        shardId = props.rootwebsite.websiteid;
    }
    if (requestMgr) {
        trackError(requestMgr, shardType, shardId, error, "", loc);
    }
}

@connectwith((state: States.IAppState) => {
    return {
        event: state.event,
        community: state.community,
        rootwebsite: state.rootwebsite,
        user: state.user,
        i18n: state.i18n
    };
})
class PageComponent extends React.Component<IPageProps, any> {
    componentDidMount() {
        if (!this.state?.initialized == true) {
            this.setState({ initialized : true });
        }
    }

    componentDidCatch(error, info) {
        this.setState({ hasError: true });
        handlePageError(this.props, error, info);
    }

    componentDidUpdate() {
        if (this.props.history && this.props.history.location) {
            const selected = this.props.history.location.hash;
            if (selected && selected.length > 0) {
                try {
                    const elem = document.querySelector(selected);
                    if (elem) {
                        elem.scrollIntoView();
                    }
                } catch {
                    // console.log("cannot navigate to hash", error);
                }
            }
        }
    }

    render() {
        if (this.state?.hasError) {
            return <div className={"app-page haserror" + (this.props.className || '')}>
                <div className="message">Unexpected error...</div>
            </div>;
        }

        if (this.props.allowedOnly) {
            const currentUser = (this.props.user.currentUser?.detail || this.props.user.currentUser?.member);
            const isRegistered = this.props.user.currentUser?.isRegistered === true;

            if (!currentUser || !isRegistered) {
                if (!this.state?.initialized) {
                    return <div className={"app-page " + (this.props.className || '')} />;
                }
                
                return <React.Suspense fallback={<></>}>
                    <RequireConnexionPage
                        {...this.props}
                        message={this.props.message}
                        registeredmessage={this.props.registeredmessage}
                    />
                </React.Suspense>;
            }
        }

        return <div className={"app-page " + (this.props.className || '')}>
            {this.props.children}
        </div>;
    }
}

export const Page : new (any)
=> React.Component<IPageProps, any> = withRouter(PageComponent as any) as any;

export interface IAsyncPageProps {
    componentProps: any;
    getComponent: any;
    title?: string;
    subtitle?: string;
    minDelay?: number;
    loaderClassName?: string;
    className?: string;
    location?: States.ILocation;
    event?: States.IEventState;
    community?: States.ICommunityState;
    rootwebsite?: States.IRootWebsiteState;
}

class AsyncPageComponent extends React.Component<IAsyncPageProps, any> {
    location: string;

    constructor(props: IAsyncPageProps) {
        super(props);
        this.location = props.location && props.location.pathname;
    }

    componentDidCatch(error, info) {
        this.setState({ hasError: true });
        handlePageError(this.props, error, info);
    }

    render() {
        if (this.state && this.state.hasError) {
            return <div>Unexpected error...</div>;
        }

        return <div className="app-page nopadding">
            <Loadable
                loader={() => {
                    return <div className="pageloader">
                        <Loader />
                    </div>;
                }}
                className={this.props.className}
                loaderClassName={this.props.loaderClassName}
                minDelay={this.props.minDelay}
                componentProps={this.props.componentProps}
                component={() => new Promise(this.props.getComponent).then((component) => {
                    return component;
                })}
            />
        </div>;
    }
}

function mapStateToProps(state: States.IAppState) {
    return {
        event: state.event,
        community: state.community,
        rootwebsite: state.rootwebsite
    };
}

function mapDispatchToProps() {
    return { };
}

export const AsyncPage : new (any: IAsyncPageProps)
=> React.Component<IAsyncPageProps, any> = connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(AsyncPageComponent as any) as any) as any;

function valFromArgs(val, args) {
    if (val && typeof val === "function") {
        return val(args);
    }
    return val;
}

interface IDynPageProps extends IDefaultEventPageProps {
    pageActions?: typeof pageActions;
}
export interface IGetPageClassOptions {
    locationAware?: boolean,
    allowedOnly?: boolean,    
    entitykind?: string | ((arg: any) => string);
    parententityid?: string | ((arg: any) => string);
    entityid?: string | ((arg: any) => string);
    contenttype?: string | ((arg: any) => string);
    application?: string | ((arg: any) => string);
    requireEntity?: boolean | ((arg: any) => boolean);
    refreshEntityWhenLogged?: boolean;
    customEntity?: any;
    entityProvider?: IEntityProviderCallback;
    pageFilter?: (pagetemplate: Entities.IContentTemplate, location: States.ILocation, match: States.ILocationMatch) => boolean;
}

interface IDynPageState {
    pagetemplate: any;
    entitykind: string;
    entityid: string;
    parententityid: string;
    contentType: string;
    application: string;
    requireEntity: any;
    isFromSsr: boolean;
    ready?: boolean;
    hasError?: boolean;
}

export function getPageClass(pagetemplate, pageClassName, opts?: IGetPageClassOptions) {
    let options = opts;
    options = options || {};

    const pagebase = class PageBaseClass extends React.Component<IDynPageProps, IDynPageState> {
        initPromise: Promise<any>;

        constructor(props: IDynPageProps) {
            super(props);
            this.state = {
                pagetemplate: valFromArgs(pagetemplate, props),
                entitykind: valFromArgs(options.entitykind, props),
                entityid: valFromArgs(options.entityid, props),
                parententityid: valFromArgs(options.parententityid, props),
                contentType: valFromArgs(options.contenttype, props) || "page",
                application: valFromArgs(options.application, props) || "companion",
                requireEntity: valFromArgs(options.requireEntity, props),
                isFromSsr: isFromServer()
            };

            this.initPromise = props.pageActions.initCurrentPage(
                { location: props.location, match: props.match },
                this.state.pagetemplate,
                {
                    entityKind: this.state.entitykind,
                    entityid: this.state.entityid,
                    parententityid: this.state.parententityid,
                    contenttype: this.state.contentType,
                    entity: !opts?.requireEntity ? opts?.customEntity : null,
                    application: this.state.application,
                    requireEntity: this.state.requireEntity,
                    entityProvider: opts?.entityProvider,
                    pageFilter: opts?.pageFilter ? this.pageFilter : undefined
                }
            ) as any;
        }

        pageFilter = (item: Entities.IContentTemplate) => {
            return options.pageFilter(item, this.props.location, this.props.match);
        };

        shouldComponentUpdate(nextprops: IDynPageProps) {
            if (options && options.locationAware && nextprops.match !== this.props.match) {
                return false;
            }

            return true;
        }

        componentDidMount() {
            if (this.state.isFromSsr && options.refreshEntityWhenLogged
                && options.entityProvider && !!this.props.user.currentUser) {
                this.initPromise.then(() => {
                    this.reloadEntity();
                });
            }

            this.initPromise.then(() => {
                this.setState({ ready: true });
                
            });
        }

        reloadEntity() {
            const entityKind = typeof options.entitykind == "string"
                ? options.entitykind
                : options.entitykind(this.props);
            const entityId = typeof options.entityid == "string"
                ? options.entityid
                : options.entityid(this.props);
            const parentId = options.parententityid && (typeof options.parententityid == "string"
                ? options.parententityid
                : options.parententityid(this.props));

            options.entityProvider(entityKind, entityId, parentId).then((res) => {
                    
                this.props.pageActions.patchCurrentPageEntity(res);
            });
        }

        componentDidUpdate(prevProps: Readonly<IDynPageProps>): void {
            if (this.props.user.currentUser
                && !prevProps.user.currentUser
                && options.refreshEntityWhenLogged
                && options.entityProvider) {
                
                this.initPromise.then(() => {
                    this.reloadEntity();
                });                
            }
        }

        componentDidCatch(error, info) {
            this.setState({ hasError: true });
            handlePageError(this.props, error, info);
        }

        render() {
            let content;
            // if (this.state?.hasError) {
            //     return <div>Unexpected error...</div>;
            // }

            if (!this.state.isFromSsr && !this.state.ready) {
                content = <div className="message">
                    <Loader />
                </div>;
            } else {
                content = <DynamicPage
                    template={this.state.application + "." + this.state.contentType + "." + this.state.pagetemplate}
                    {...this.props}
                />;
            }
            return <Page {...this.props} allowedOnly={options.allowedOnly} className={pageClassName}>
                {content}
            </Page>;
        }
    };

    function mapPageStateToProps(state: States.IAppState) {
        return {
            event: state.event,
            rootwebsite: state.rootwebsite,
            user: state.user,
            page: state.pages.currentPage,
            i18n: state.i18n,
        };
    }

    function mapPageDispatchToProps(dispatch) {
        return {
            pageActions: bindActionCreators(pageActions, dispatch),
        };
    }

    const currentPageClass: new (any) => React.Component<any, any> = connect(
        mapPageStateToProps,
        mapPageDispatchToProps
    )(pagebase as any) as any;

    return currentPageClass;
}
